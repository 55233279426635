// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quality-policy-js": () => import("./../src/pages/quality-policy.js" /* webpackChunkName: "component---src-pages-quality-policy-js" */),
  "component---src-pages-services-consultancy-js": () => import("./../src/pages/services/consultancy.js" /* webpackChunkName: "component---src-pages-services-consultancy-js" */),
  "component---src-pages-services-mvp-development-js": () => import("./../src/pages/services/mvp-development.js" /* webpackChunkName: "component---src-pages-services-mvp-development-js" */),
  "component---src-pages-services-team-augmentation-js": () => import("./../src/pages/services/team-augmentation.js" /* webpackChunkName: "component---src-pages-services-team-augmentation-js" */),
  "component---src-templates-blog-index-js": () => import("./../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-careers-index-js": () => import("./../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-expert-guides-js": () => import("./../src/templates/expert-guides.js" /* webpackChunkName: "component---src-templates-expert-guides-js" */),
  "component---src-templates-guides-index-js": () => import("./../src/templates/guides/index.js" /* webpackChunkName: "component---src-templates-guides-index-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-work-index-js": () => import("./../src/templates/work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */),
  "component---src-templates-work-js": () => import("./../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

